@font-face {
  font-family: technor;
  src: url(./assets/font/Technor-Regular.otf);
}
@font-face {
  font-family: technorBold;
  src: url(./assets/font/Technor-Bold.otf);
}
@font-face {
  font-family: technorSemibold;
  src: url(./assets/font/Technor-Semibold.otf);
}
@font-face {
  font-family: technorLight;
  src: url(./assets/font/Technor-Light.otf);
}
@font-face {
  font-family: supreme;
  src: url(./assets/font/Supreme.ttf);
}
@font-face {
  font-family: supremeItalic;
  src: url(./assets/font/SupremeItalic.ttf);
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @layer base { */
ol {
  list-style: decimal !important;
}
/* } */

html {
  scroll-behavior: smooth;
}

* {
  font-family: technor;
}



.satoshi {
  font-family: "Satoshi", sans-serif;
}

.alice-carousel__dots {
  display: none;
}

.contrast {
  filter: contrast(50%);
  /* filter: saturate(70%); */
}
::placeholder {
  color: black; /* Example color */
  font-size: 14px; /* Example font-size */
  /* Add any other styles you want for the placeholder */
}

/* .more-info-bg {
    background-image: url(./assets/images/);
} */

.db-footer-img {
  background-image: url(./assets/images/bottomBoxImg.svg);
  background-repeat: no-repeat;
  object-fit: cover;
}

.applyNowBg {
  background-image: url('./assets/images/production/applyNowBg.svg');
  background-repeat: none;
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  width: 100%;
}

.bg {
  background:
        /* Darkening overlay */ linear-gradient(
      to bottom right,
      rgba(254, 242, 198, 0.5) 0%,
      rgba(254, 242, 198, 0) 25%,
      rgba(203, 255, 235, 0) 75%,
      rgba(203, 255, 235, 0.5) 100%
    ),
    /* Original gradient */
      linear-gradient(
        90deg,
        rgba(252, 247, 228, 1) 0%,
        rgba(247, 252, 244, 1) 47%,
        rgba(222, 250, 239, 1) 100%
      );
}

.bg_about_img {
  background-image: url(./assets/images/about-bg.png);
  background-repeat: repeat-x;
  object-fit: cover;
}

.instructor-img-bg {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1195071778711485) 12%,
    rgba(0, 0, 0, 0.5032606792717087) 45%
  );
}

.background-img_ {
  background-image: url(./assets/icons/tilePatternBg.svg);
}

.privacy-policy ul {
  list-style: disc;
  padding: 0 30px;
}

.privacy-policy ul li {
  font-family: "supreme";
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}
.privacy-policy ul li b {
  font-family: "supreme";
}

.constant_link {
  color: "#1C7272";
}

.supreme {
  font-family: "supreme";
}

.alex-brush-regular {
  font-family: "Alex Brush", cursive;
}

.indie-flower-regular {
  font-family: "Indie Flower", cursive;
}
input::placeholder {
  font-family: supreme;
  font-size: 12px;
  font-weight: 200;
}

.MuiPagination-ul {
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dcdee2;
  border-radius: 5px;
}
.MuiPagination-ul li {
  border-right: 1px solid #dcdee2;
  color: #dcdee2 !important;
}

.MuiAccordionSummary-root {
  background-color: rgb(239, 239, 239) !important;
}
.react-player {
  background: #000;
  height: 100% !important;
  width: 100% !important;
  border: 2px solid #dcdee2;
  border-radius: 20px !important;
  overflow: hidden;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

/* pls, is it possible to rename this?...it is disrupting another style i created  */

@keyframes spinner {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #277172;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spinner linear infinite;
}

.loader:after {
  color: #1c7272;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

p {
  font-family: "supreme";
}

.border-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1195071778711485) 14%,
    rgba(209, 227, 227, 0.640515581232493) 31%,
    rgba(12, 70, 70, 0.9906556372549019) 91%
  );
}

.Toastify__toast {
  font-family: "supreme" !important;
}
.Toastify__toast-body {
  font-family: "supreme" !important;
}

.deactivateScrollbar::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 512px) {
  .MuiPagination-root .MuiPagination-text .css-1oj2twp-MuiPagination-root {
    margin: 0 0;
    min-width: 35px;
    height: 350px;
    border-radius: 17.5px;
    width: 10px;
  }
}

/* .css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root */

/* css-1oj2twp-MuiPagination-root-MuiPagination-root   */

/* .css-wjh20t-MuiPagination-ul-MuiPagination-ul */

/* .MuiPagination-ul .css-wjh20t-MuiPagination-ul */
